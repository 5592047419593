import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import AuthState from "./context/AuthState";
import SessionDetails from "./pages/SessionDetails";
import LoginForm from "./components/LoginForm";
// import SignupForm from "./components/SignupForm";
import { Toaster } from "sonner";
import AccountPage from "./pages/AccountPage";
import FrameWorkPage from "./pages/FrameWorkPage";
import SingleFrameworkPage from "./pages/SingleFrameworkPage";
import BusinessProfileGenerator from "./pages/BusinessProfileGenerator";
import SavedFrameworks from "./pages/SavedFrameworks";
import SidebarLayout from "./pages/SidebarLayout";
import "react-loading-skeleton/dist/skeleton.css";
function App() {
  // Check if the current location is on the login page

  return (
    <div className="h-screen">
      <Toaster richColors expand={true} />
      <BrowserRouter>
        <AuthState>
          <Routes>
            <Route path="login" element={<LoginPage />}>
              <Route index element={<LoginForm />} />
              {/* <Route path="signup" element={<SignupForm />} /> */}
            </Route>
          </Routes>

          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <ProtectedRoutes />
                </>
              }>
              <Route path="/" element={<SidebarLayout />}>
                <Route index element={<HomePage />} />
                <Route
                  path="/chathistory/:sessionId"
                  element={<SessionDetails />}
                />
              </Route>

              <Route path="account" element={<AccountPage />} />
              <Route path="frameworks" element={<FrameWorkPage />} />
              <Route
                path="frameworks/:framework"
                element={<SingleFrameworkPage />}
              />
              <Route
                path="businessProfile"
                element={<BusinessProfileGenerator />}
              />
              <Route path="savedFrameworks" element={<SavedFrameworks />} />
            </Route>
          </Routes>
        </AuthState>
      </BrowserRouter>
    </div>
  );
}

export default App;
