import { useCallback, useState, useEffect } from "react";
import {
  QueryClient,
  useMutation,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { toast } from "sonner";
import getAccessToken from "../utils/getAccessToken";
import { apiUrl } from "../utils/constants";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

export default function useSession() {
  //to see if the last session is in view
  const { ref, inView } = useInView();

  const [prevSessions, setPrevSessions] = useState([]);
  //infinite query to fetch sessions
  const { status, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["sessions"],
      initialPageParam: 1,
      queryFn: async ({ pageParam }) =>
        fetch(apiUrl + `api/sessions/${pageParam}`, {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: "Bearer " + (await getAccessToken()),
          },
        }).then((res) => res.json()),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length ? allPages.length + 1 : undefined;

        return nextPage;
      },
      staleTime: Infinity,
    });

  useEffect(() => {
    // console.log(data);
    if (data) {
      let tempsessions = [];
      data.pages.forEach((page) =>
        page.forEach((session) => {
          tempsessions.push(session);
        })
      );

      setPrevSessions(tempsessions);
    }
  }, [data]);
  //refetch when last session is in view
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);
  //session state
  const [sessionState, setSessionState] = useState(null);

  //handle session state
  const changeSession = useCallback((newSessionState) => {
    setSessionState(newSessionState);
  }, []);

  //mutate query to rename chat
  const queryClient = new QueryClient();
  const { mutate: renameChat } = useMutation({
    mutationFn: async (requestBody) => {
      const response = await fetch(apiUrl + "api/renameChat/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + (await getAccessToken()),
        },
        body: JSON.stringify(requestBody),
      }).then((res) => res.json());
      return response;
    },
    onMutate: async ({ sessionId, newName }) => {
      let oldSessions = prevSessions;

      const temp = oldSessions.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            sessionName: newName,
          };
        } else {
          return session;
        }
      });
      setPrevSessions(temp);
      navigate("/");
      return { oldSessions };
    },
    onError: (_, __, context) => {
      setPrevSessions(context.oldSessions);
      toast.error("Somthing went wrong, could not rename chat.");
    },
    onSuccess: async (data, _, context) => {
      if (data.error) {
        setPrevSessions(context.oldSessions);
        toast.error("Somthing went wrong, could not rename chat.");
        await queryClient.invalidateQueries({ queryKey: ["sessions"] });
      } else {
        await queryClient.invalidateQueries({ queryKey: ["sessions"] });
        toast.success(`"${data.sessionName}" renamed successfully.`);
      }
    },
  });

  //handle rename
  const handleRename = useCallback(
    (sessionId, newName, oldName) => {
      if (newName === oldName) {
        return changeSession(null);
      }

      if (!newName) {
        return toast.error("Chat cannot have an empty name.");
      }

      if (newName.length > 200) {
        return toast.error("Name of a chat cannot exceed 200 characters.");
      }
      changeSession(null);
      renameChat({ sessionId, newName });
    },
    [changeSession, renameChat]
  );

  //delete session
  const navigate = useNavigate();
  const { mutate: deleteChat } = useMutation({
    mutationFn: async (sessionId) => {
      const response = await fetch(apiUrl + "api/deleteChat/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + (await getAccessToken()),
        },
        body: JSON.stringify({ sessionId: sessionId }),
      }).then((res) => res.json());
      return response;
    },
    onMutate: async (sessionId) => {
      let oldSessions = prevSessions;
      setPrevSessions((prev) =>
        prev.filter((session) => session.sessionId !== sessionId)
      );
      navigate("/");
      return { oldSessions };
    },
    onSuccess: (data, _, context) => {
      if (data.error) {
        setPrevSessions(context.oldSessions);
        toast.error("Somthing went wrong, could not delete chat.");
      } else {
        toast.success(`${data.sessionName} was deleted.`);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["sessions"] });
    },
  });

  const states = {
    sessionState,
    changeSession,
    handleRename,
    status,
    sessionData: prevSessions,
    isFetchingNextPage,
    ref,
    deleteChat,
  };

  return states;
}
