import React, { useState } from "react";
import { memo, forwardRef } from "react";
import Dots from "./Dots";
import Popover from "./Popover";
import RenameField from "./RenameField";
import RedirectSession from "./RedirectSession";
import { toast } from "sonner";
const Session = memo(
  forwardRef(
    (
      { session, handleRename, changeSession, generating },
      { popoverRef, inputRef }
    ) => {
      const { sessionName, sessionState, isInView } = session;

      const isRename = sessionState ? sessionState.isRename : false;

      const [name, setName] = useState(sessionName);
      const handleChangeRenameField = (e) => setName(e.target.value);

      return (
        <div className="w-full relative group ">
          {/*Show Input field or redirect button based on isRename*/}
          {isRename ? (
            <RenameField
              session={session}
              name={name}
              handleRename={handleRename}
              handleChangeRenameField={handleChangeRenameField}
              ref={inputRef}
            />
          ) : generating ? (
            <button
              onClick={() =>
                toast.error("Cannot switch chats while answer is generating")
              }
              className={`w-full slide-in py-3 px-2 rounded-md ${
                isInView ? "bg-[#00285E]/10" : "group-hover:bg-[#00285E]/5"
              }  flex justify-between items-center z-10 truncate overflow-ellipsis `}>
              <p className=" line-clamp-2 max-w-[85%]"> {name}</p>
            </button>
          ) : (
            <RedirectSession session={session} name={name} />
          )}

          {/* hide popover when input field is open  */}
          {!isRename ? (
            <>
              <Popover
                session={session}
                name={name}
                changeSession={changeSession}
                ref={popoverRef}
              />
              <Dots session={session} changeSession={changeSession} />
            </>
          ) : null}
        </div>
      );
    }
  )
);

export default Session;
