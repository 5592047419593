import { useRef } from "react";

import useChat from "../hooks/useChat";
import Messages from "../components/Messages";
import ChatInput from "../components/ChatInput";
import { useOutletContext } from "react-router-dom";

export default function HomePage() {
  const chatRef = useRef(null);
  const {
    isPending,
    currentQuestion,
    messages,
    output,
    handleSubmit,
    handleInputChange,
  } = useChat({ existingSessionId: null, isHistoryPage: false, chatRef });
  const { isOpen } = useOutletContext();

  return (
    <>
      <div className="w-full overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
        <div
          ref={chatRef}
          className="w-full px-2.5 md:px-20 max-w-screen-lg mx-auto">
          <Messages messages={messages} output={output} isloading={isPending} />
        </div>
      </div>
      <div
        className={`fixed bottom-0 transition-all duration-300 ${
          isOpen ? "lg:max-w-[calc(100%-18rem)]" : ""
        }  w-full  px-2.5 md:px-20   bg-gradient-to-b from-transparent to-white to-25% h-32 pt-8 `}>
        <ChatInput
          value={currentQuestion}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
}
