import { useEffect, useRef } from "react";

import ChatInput from "../components/ChatInput";

import useChat from "../hooks/useChat";

import Messages from "../components/Messages";
import { useLocation, useOutletContext } from "react-router-dom";
import { Loader2 } from "lucide-react";

function getFinalPath(url) {
  const urlParts = url.split("/");
  const finalString = urlParts[urlParts.length - 1];
  return finalString;
}

const SessionDetails = () => {
  const existingSessionId = getFinalPath(useLocation().pathname);
  const chatRef = useRef(null);

  const {
    isPending,
    currentQuestion,
    messages,
    output,
    handleSubmit,
    handleInputChange,
    fetchHistory,
    isFetchingHistory,
  } = useChat({ existingSessionId, isHistoryPage: true, chatRef });

  useEffect(() => {
    fetchHistory(existingSessionId);
  }, [existingSessionId, fetchHistory]);

  const { isOpen } = useOutletContext();

  if (isFetchingHistory || !messages) {
    return (
      <div className="w-full  h-[calc(100vh-7rem)] flex justify-center items-center">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className="w-full overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
        <div
          ref={chatRef}
          className="w-full px-2.5 md:px-20 max-w-screen-lg mx-auto">
          <Messages messages={messages} output={output} isloading={isPending} />
        </div>
      </div>
      <div
        className={`fixed bottom-0 transition-all duration-300 ${
          isOpen ? "lg:max-w-[calc(100%-18rem)]" : ""
        }  w-full  px-2.5 md:px-20   bg-gradient-to-b from-transparent to-white to-25% h-32 pt-8`}>
        <ChatInput
          value={currentQuestion}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default SessionDetails;
