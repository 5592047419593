import React from "react";

import TextareaAutosize from "react-textarea-autosize";
import { SendHorizonal } from "lucide-react";

const ChatInput = ({ value, handleSubmit, handleInputChange }) => {
  const handleTextareaKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className={` w-full px-2.5 md:px-0  md:max-w-3xl  mx-auto`}>
      <div className="relative w-full">
        <TextareaAutosize
          id="chat"
          maxRows={3}
          className=" xs:text-base text-xs   flex w-full rounded-full  resize-none border-2 border-border  scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch px-3  ring-offset-background  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-blue focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  py-1.5 focus:outline-0  text-gray-900 bg-white h-6 max-h-24 xs:h-9 xs:max-h-36"
          placeholder="Send your message"
          value={value}
          autoFocus
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={handleTextareaKeyPress}
        />
        <button
          type="submit"
          disabled={value.length <= 0}
          className="disabled:opacity-50">
          <SendHorizonal className="h-5 w-5 text-black absolute right-4 top-1.5  xs:top-2.5" />
          <span className="sr-only">Send message</span>
        </button>
      </div>
    </form>
  );
};

export default ChatInput;
