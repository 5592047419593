import React from "react";
import Logo from "../assets/logo.png";
import Bot from "../assets/chatbot.png";
import { Outlet } from "react-router-dom";

const LoginPage = () => {
  // let location = useLocation();
  // let isLoginPage = location.pathname === "/login";

  return (
    <>
      <section className=" dark:bg-gray-900 flex flex-col lg:flex-row h-screen">
        <div className="relative flex  items-end  h-screen bg-primary-blue/95  lg:w-1/2 py-12 lg:py-5">
          <img
            src={Bot}
            alt=""
            className="h-full w-full absolute z-10 opacity-10 object-cover"
          />
          <div className="max-w-lg lg:max-w-3xl mx-auto px-5 z-20  flex justify-between gap-y-8 lg:gap-y-0 flex-col lg:h-[calc(50vh+2rem)]">
            <div className="text-white text-center  space-y-5">
              <h1 className="text-2xl lg:text-4xl font-bold ">The Playbook</h1>
              <h2 className="text-xl lg:text-2xl  font-semibold">
                An AI Powered Chat-bot for all your Sales, Marketing and
                Business Needs{" "}
              </h2>
            </div>
            {/* <div className="self-start justify-self-end w-32 lg:w-64 text-center z-30  text-white ">
              <h5 className=" text-base lg:text-lg ">
                {isLoginPage ? "New member?" : "Already registered? "}
              </h5>
              <Link
                className="w-full  flex items-center justify-center text-white disabled:opacity-75 disabled:hover:bg-primary-pink bg-primary-pink hover:bg-primary-red focus:outline-1 focus:outline-red-300  font-medium 
    rounded-lg text-xs xs:text-sm px-5 py-2.5 text-center "
                to={isLoginPage ? "signup" : "/login"}>
                {isLoginPage ? "Sign up" : "Log in"}
              </Link>
            </div> */}
          </div>
        </div>
        <div className="flex flex-col items-center lg:w-1/2  max-h-screen  justify-center  px-6 mx-auto  lg:py-0">
          <img
            src={Logo}
            alt="Entrouge Logo"
            className="w-[calc(26*0.75rem)] lg:w-[26rem] mt-7 xs:mt-10"
          />
          <div className="w-full rounded-lg max-w-lg md:mt-0  xl:p-0 ">
            <div className="p-6  sm:p-8 ">
              <h1 className="text-xl font-bold  leading-tight tracking-tight text-gray-900 lg:text-2xl ">
                Sign In
              </h1>

              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
