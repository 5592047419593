import React, { forwardRef, memo } from "react";
import { Edit, Trash } from "lucide-react";

const Popover = forwardRef(({ session, changeSession, name }, popoverRef) => {
  const { sessionId, sessionState } = session;
  const isMenuOpen = sessionState ? sessionState.isMenuOpen : false;
  const isRename = sessionState ? sessionState.isRename : false;

  return (
    <div
      ref={isMenuOpen ? popoverRef : undefined}
      className={`${
        isMenuOpen
          ? "absolute top-10 right-0 flex flex-col gap-y-2 justify-center items-start"
          : "hidden"
      } bg-white w-40 h-24 z-50 rounded-md border-2 border-zinc-300 py-4 px-5`}>
      <button
        onClick={() =>
          changeSession(
            isRename
              ? null
              : {
                  sessionId,
                  isMenuOpen: false,
                  isRename: true,
                  deleteSession: null,
                }
          )
        }
        className="w-full flex  gap-x-2.5 items-center text-lg hover:underline underline-offset-2 hover:bg-[#00285E]/5 px-2 py-0.5 rounded-md">
        <Edit className="h-4 w-4" />
        <span>Rename</span>
      </button>
      <button
        onClick={() =>
          changeSession(
            isRename
              ? null
              : {
                  sessionId,
                  isMenuOpen: false,
                  isRename: false,
                  deleteSession: name,
                }
          )
        }
        className="w-full flex  gap-x-2.5 items-center text-lg hover:underline underline-offset-2 hover:bg-[#00285E]/5 px-2 py-0.5 rounded-md">
        <Trash className="h-4 w-4" />
        <span>Delete</span>
      </button>
    </div>
  );
});

export default memo(Popover);
