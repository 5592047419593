import React, { useState } from "react";
import Button from "./Button";
import getAccessToken from "../utils/getAccessToken";
import { apiUrl } from "../utils/constants";
import { useNavigate } from "react-router-dom";
const BPG = ({ output }) => {
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    const token = await getAccessToken();

    try {
      const response = await fetch(apiUrl + "api/saveBusinessProfile/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          businessProfileName: e.target.businessProfileName.value,
          businessProfileText: output,
        }),
      });
      if (!response.body) {
        console.error("Response body not available.");
        return;
      }
      const { message } = await response.json();
      if (message === "saved") {
        alert(
          "Your Business Profile is saved, you can now use them in frameworks!"
        );
        setSaving(false);
        navigate("/frameworks");
      } else {
        setSaving(false);
        alert(message);
      }
    } catch (error) {
      alert("Uh Oh! Something went wrong");
      console.log(error);
      setSaving(false);
    }
  };
  return (
    <form
      className="flex flex-col gap-y-2 min-w-[20rem] w-full "
      onSubmit={handleSave}>
      {" "}
      <input
        required
        placeholder="Business Profile name"
        name="businessProfileName"
        className="rounded-md px-4 h-10 w-full  placeholder:font-[300]  font-normal text-xs xs:text-sm
    border-2 border-zinc-300 focus:border-zinc-800 disabled:bg-black/5 disabled:border-black/20"
      />
      <Button type="submit" disabled={saving} loading={saving}>
        Save Bussiness Profile{" "}
      </Button>
    </form>
  );
};

export default BPG;
