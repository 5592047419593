import React, { memo } from "react";
import DotsIcon from "../../assets/3-Dots.png";

const Dots = ({ session, changeSession }) => {
  const { sessionId, sessionName, sessionState, isInView } = session;
  const isMenuOpen = sessionState ? sessionState.isMenuOpen : false;
  return (
    <div
      className={`absolute right-2 top-3 ${
        !isMenuOpen && !isInView && "hidden"
      }  gap-x-0   md:group-hover:flex z-20 pr-2 `}>
      <button
        onClick={() => {
          changeSession(
            isMenuOpen
              ? null
              : {
                  sessionId,
                  isMenuOpen: true,
                  isRename: false,
                  delete: null,
                }
          );
        }}>
        <img
          id={`${sessionId}-img`}
          src={DotsIcon}
          alt={`open ${sessionName}`}
        />
      </button>
    </div>
  );
};

export default memo(Dots);
