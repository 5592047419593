import React from "react";

import TextareaAutosize from "react-textarea-autosize";

const FrameworkTextField = ({ placeholder, id, name, loading = false }) => {
  return (
    <TextareaAutosize
      id={id}
      placeholder={placeholder}
      minRows={5}
      name={name}
      disabled={loading}
      required
      autoFocus={id.split("-")[0] === "0"}
      className="rounded-md px-4 py-2  placeholder:font-[300] font-normal text-sm border-2 
          scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch
           border-zinc-300 focus:border-zinc-800 disabled:bg-black/5 disabled:border-black/20"
    />
  );
};

export default FrameworkTextField;
