import React, { useEffect, useRef, useState } from "react";

import Button from "../components/Button";
import FrameworkTextField from "../components/FrameworkTextField";
import FrameworkInputField from "../components/FrameworkInputField";
import { convertToCamelCase } from "../utils";
import getAccessToken from "../utils/getAccessToken";
import { apiUrl } from "../utils/constants";
import BPG from "../components/BPG";
import { bsgData } from "../FrameworkData";
import TextareaAutosize from "react-textarea-autosize";
import CopyBtn from "../components/CopyBtn";
import { Edit, Loader2, Save, Send, X, XCircle } from "lucide-react";

const BusinessProfileGenerator = () => {
  const [generating, setGenerating] = useState(false);
  const [isAiThinking, setIsAiThinking] = useState(false);
  const [output, setOutput] = useState("");
  const outputRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const dialogRef = useRef(null);
  const handleOutputEdit = (e) => {
    setOutput(e.target.value);
    if (!e.target.value) setEditMode(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOutput("");
    setIsAiThinking(true);
    setGenerating(true);

    let promptParams = {};

    for (let i = 0; i < e.target.length - 1; i++) {
      const element = e.target[i];
      promptParams[element.name] = element.value;
    }

    const token = await getAccessToken();

    try {
      const response = await fetch(apiUrl + "api/businessProfileGenerator/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          promptParams,
        }),
      });
      if (!response.body) {
        console.error("Response body not available.");
        alert("something went wrong");
        setGenerating(false);
        setIsAiThinking(false);
        return;
      }

      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();
      setIsAiThinking(false);
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setGenerating(false);
          setIsAiThinking(false);
          break; // End of stream
        }
        const renderedData = textDecoder.decode(value, { stream: true });
        setOutput((prev) => `${prev}${renderedData}`);
      }
    } catch (error) {
      setGenerating(false);
      setIsAiThinking(false);
      alert("something went wrong");
    }
  };
  useEffect(() => {
    outputRef.current?.scrollIntoView({ behavior: "instant", block: "end" });
  }, [output, generating]);
  return (
    <div className=" bg-[#EBEBEB] gap-y-10 py-10 flex flex-col justify-center items-center off-smooth-scroll">
      <div className="w-full px-10 max-w-5xl   space-y-10 min-h-[calc(100vh-10rem)] ">
        <div className="w-full space-y-3">
          <h1 className=" text-3xl font-bold text-primary-blue">
            {bsgData.name}
          </h1>
          <p className="whitespace-pre-line">{bsgData.description}</p>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-5">
          {bsgData.formFields?.map((field, index) => {
            let id = index + "-" + field.label;
            let name = convertToCamelCase(field.label);
            return (
              <div key={index} className="flex flex-col space-y-2">
                <label className="font-bold text-black/75" htmlFor={id}>
                  {field.label}
                </label>
                {typeof field.type !== "undefined" ? (
                  <FrameworkInputField
                    key={id}
                    id={id}
                    placeholder={field.placeholder}
                    name={name}
                    loading={generating}
                  />
                ) : (
                  <FrameworkTextField
                    key={id}
                    id={id}
                    placeholder={field.placeholder}
                    name={name}
                    loading={generating}
                  />
                )}
              </div>
            );
          })}

          <div className="self-end">
            <Button
              type="submit"
              name="Submit Form"
              disabled={generating || editMode}
              loading={generating}>
              <Send className="h-4 w-4" />
            </Button>
          </div>
        </form>
        {isAiThinking || output ? (
          <div className="sm:max-w-full mt-12  mx-auto space-y-8">
            <h6 className="font-bold text-lg xs:text-2xl text-black/75">
              Generated Output:
            </h6>

            <div className="flex">
              <div className="bg-white  w-full  flex justify-start px-3 xs:px-5 py-4 xs:py-8 drop-shadow-md border rounded-lg  z-30 border-zinc-400">
                {isAiThinking ? (
                  <div className="self-center flex flex-row items-start gap-x-2 xs:gap-x-8 mx-auto">
                    <Loader2 className="w-3 xs:w-6 aspect-square animate-spin" />
                    <div className="flex gap-x-2">
                      <p className="text-xs xs:text-base">
                        Generating your answer{" "}
                      </p>
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "300ms" }}>
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "600ms" }}>
                        .
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="text-xs xs:text-base w-full text-zinc-800 text-left  whitespace-pre-wrap prose ">
                    {editMode ? (
                      <TextareaAutosize
                        autoFocus
                        onChange={handleOutputEdit}
                        className="w-full  p-2 rounded-md whitespace-pre-wrap prose   scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touc"
                        value={output}
                      />
                    ) : (
                      <>
                        {output}
                        {generating && <span className="animate-ping">|</span>}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!generating ? (
              <div className=" items-center w-full flex justify-evenly flex-col md:flex-row gap-y-4  self-center">
                <div className="md:basis-1/4">
                  <CopyBtn useIcon={false} text={output} />
                </div>
                <div className="md:basis-1/4">
                  <Button onClick={() => setEditMode(!editMode)}>
                    {editMode ? (
                      <>
                        <X className="w-4 h-4 mr-2" /> Close Editing Mode
                      </>
                    ) : (
                      <>
                        <Edit className="h-4 w-4 mr-2" /> Open Editing Mode
                      </>
                    )}
                  </Button>
                </div>
                <div className="md:basis-1/4">
                  <Button
                    disabled={editMode}
                    onClick={() => dialogRef.current.showModal()}
                    type="button">
                    {editMode ? (
                      "Close Edit Mode before Saving"
                    ) : (
                      <>
                        <Save className="h-4 w-4 mr-2" /> Save as Favourite
                      </>
                    )}
                  </Button>
                </div>
              </div>
            ) : null}
            <div ref={outputRef} className="h-10" />
          </div>
        ) : null}
      </div>
      <dialog
        ref={dialogRef}
        className="space-y-7 rounded-md p-4 border border-black">
        <div className="flex justify-between">
          <h3 className=" text-2xl font-bold text-primary-blue">
            Save Business Profile
          </h3>
          <button
            type="button"
            onClick={() => dialogRef.current.close()}
            className=" text-red-500 font-bold">
            <XCircle />
          </button>
        </div>
        <BPG output={output} />
      </dialog>
    </div>
  );
};

export default BusinessProfileGenerator;
