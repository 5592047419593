import React, { useState } from "react";
import Button from "../Button";
import { apiUrl } from "../../utils/constants";

const SendOtpForm = ({
  email,
  handleInputChange,
  updateOtpProgress,
  updateValidUntil,
}) => {
  const [sendingOtp, setSendignOtp] = useState(false);
  const [error, setError] = useState("");

  const sendOtp = async (e) => {
    e.preventDefault();
    setSendignOtp(true);
    setError("");
    const response = await fetch(apiUrl + "api/forgotPassword/", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_email: email,
      }),
    });
    const data = await response.json();
    if (data.error) {
      setSendignOtp(false);
      setError(
        "User with this email is not found. If you are a new user contact Mr. Abdo or developer Mr. Shashank"
      );
      return;
    }
    if (data.success) {
      setSendignOtp(false);
      updateOtpProgress("verify");
      updateValidUntil(data.valid_until);
    }
  };

  return (
    <form
      onSubmit={(e) => sendOtp(e)}
      className="w-full flex flex-col gap-y-6 justify-center px-4 h-[calc(24rem-12rem)]">
      <div className="flex flex-col w-full gap-y-2">
        <label
          htmlFor="user-email"
          className={`text-lg font-medium ${
            error ? " text-red-500" : "text-black"
          }`}>
          Your Entourage Email
        </label>
        <input
          autoFocus={true}
          required
          className="rounded-md px-4 h-10 w-full max-w-md placeholder:font-[300]  font-normal text-sm 
    border-2 border-zinc-300 focus:border-zinc-800 disabled:bg-black/5 disabled:border-black/20"
          value={email}
          disabled={sendingOtp}
          onChange={(e) => handleInputChange(e)}
        />
        {error ? <span className="text-red-500 text-xs">{error}</span> : null}
      </div>
      <span className="text-xs text-primary-blue">
        An One-Time Password (OTP) will be sent to the provided email. <br />
        Please verify the email properly.
      </span>
      <div className="w-1/2 mx-auto">
        <Button disabled={sendingOtp} type="submit" loading={sendingOtp}>
          Send OTP
        </Button>
      </div>
    </form>
  );
};

export default SendOtpForm;
