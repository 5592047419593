import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowDown, ArrowRight } from "lucide-react";

const Framework = ({ framework }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className="bg-[#EBEBEB]  border-b border-x first:border-t flex flex-col  border-black text-black ">
      <div
        onClick={() => setOpen(!open)}
        tabIndex="0"
        onKeyUp={(e) =>
          e.key === " " || e.code === "Space" ? setOpen(!open) : null
        }
        className="px-4 cursor-pointer py-2 flex justify-between gap-x-5 items-center font-semibold">
        <h1 className="select-none">{framework.name}</h1>
        <div
          className={`${
            open ? "rotate-180" : "rotate-0"
          } transition-all duration-300 select-none`}>
          <ArrowDown className="w-6 text-black" />
        </div>
      </div>

      <div
        className={`bg-white relative  ${
          open ? "h-auto py-4 border-t" : "h-0"
        }  transition-all ease-out duration-200 border-black select-none font-normal text-base`}>
        <div
          className={`px-3 flex items-center justify-between ${
            open ? "block" : "hidden"
          }`}>
          {framework.underconstruction ? (
            <p className="select-none w-full text-center">
              🚧 Under Constuction 🚧
            </p>
          ) : (
            <>
              <p className="select-none "> {framework.description}</p>
              <Link to={`${framework.name}`} className="  select-none">
                <ArrowRight />
              </Link>
            </>
          )}
        </div>
      </div>
    </li>
  );
};

export default Framework;
