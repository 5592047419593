import React, { useState } from "react";
import { OTPInput } from "input-otp";
import { Loader2 } from "lucide-react";
import { apiUrl } from "../../utils/constants";
const VerifyOtp = ({ email, updateOtpProgress, validUntil }) => {
  const [otp, setOtp] = useState("");
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [error, setError] = useState("");
  const verifyOtp = async () => {
    setVerifyingOtp(true);
    const response = await fetch(apiUrl + "api/verify-otp/", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp,
        email,
        valid_until: validUntil,
      }),
    }).then((res) => res.json());
    if (!response.success) {
      setVerifyingOtp(false);
      setError(response.message);
      setOtp("");
      return;
    }
    updateOtpProgress("reset");
  };
  return (
    <div className="w-full flex flex-col justify-center gap-y-6 px-4 h-[calc(24rem-12rem)]">
      <h4 className="text-lg text-center">
        An Otp has been sent to your email:{" "}
        <span className="text-primary-blue">{email}</span>.
      </h4>
      <OTPInput
        value={otp ?? ""}
        onComplete={verifyOtp}
        onChange={(value) => setOtp(value)}
        maxLength={6}
        disabled={verifyingOtp}
        containerClassName="group flex items-center has-[:disabled]:opacity-30 self-center"
        render={({ slots }) => (
          <>
            <div className="flex">
              {slots.slice(0, 3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>

            <FakeDash />

            <div className="flex">
              {slots.slice(3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>
          </>
        )}
      />
      <div className="text-center">
        {verifyingOtp ? (
          <span className="flex items-center justify-center w-full">
            <Loader2 className="h-4 w-4 animate-spin mr-2" />
            Please wait while we are validating.
          </span>
        ) : (
          <>
            {error ? (
              <span className="text-red-500"> {error} </span>
            ) : (
              "Please enter otp to verify your account."
            )}
          </>
        )}
      </div>
    </div>
  );
};
function Slot(props) {
  return (
    <div
      className={`relative w-10 h-14 text-[2rem]
        flex items-center justify-center
        transition-all duration-100
        border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md
        group-hover:border-accent-foreground/20 group-focus-within:border-accent-foreground/20
        outline-4 outline-accent-foreground
        ${
          props.isActive ? "outline outline-0 outline-accent-foreground/20" : ""
        }
      `}>
      {props.char !== null && <div>{props.char}</div>}
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}
function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-8 bg-primary-blue" />
    </div>
  );
}

// Inspired by Stripe's MFA input.
function FakeDash() {
  return (
    <div className="flex w-10 justify-center items-center">
      <div className="w-3 h-1 rounded-full bg-primary-blue" />
    </div>
  );
}
export default VerifyOtp;
