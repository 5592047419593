import { X } from "lucide-react";
import React, { useState } from "react";
import Logo from "../../assets/noTagLogo.png";
import SendOtpForm from "./SendOtpForm";
import VerifyOtp from "./VerifyOtp";
import CreateNewPassword from "./CreateNewPassword";

const Modal = ({ closeModal, inputEmail }) => {
  const [email, setEmail] = useState(inputEmail);
  const [otpProgress, setOtpProgress] = useState("send");
  const [validUntil, setValidUntil] = useState("");
  const updateValidUntil = (timestamp) => {
    setValidUntil(timestamp);
  };
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  const updateOtpProgress = (progress) => {
    setOtpProgress(progress);
  };
  const getProgressStep = () => {
    switch (otpProgress) {
      case "send":
        return (
          <SendOtpForm
            email={email}
            handleInputChange={handleInputChange}
            updateOtpProgress={updateOtpProgress}
            updateValidUntil={updateValidUntil}
          />
        );
      case "verify":
        return (
          <VerifyOtp
            email={email}
            updateOtpProgress={updateOtpProgress}
            validUntil={validUntil}
          />
        );
      case "reset":
        return <CreateNewPassword email={email} closeModal={closeModal} />;
      default:
        return (
          <div className=" flex justify-center items-center ">
            <h4 className="text-red-500 font-medium text-xl">
              Please close the dialog box and try again.
            </h4>
          </div>
        );
    }
  };
  return (
    <div className="fixed inset-0 bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="border rounded-md  p-6 animate-in zoom-in-50   duration-300  bg-white w-full max-w-lg  shadow-sm shadow-black relative flex flex-col gap-y-8 items-center">
        <div className="flex justify-between items-center w-full">
          <img src={Logo} alt="Entourage Logo" className="h-10 w-10" />
          <span className="text-2xl font-semibold text-primary-blue">
            The Playbook
          </span>
          <button className="" onClick={() => closeModal()}>
            <X className="h-6 w-6 text-red-600" />
            <span className="sr-only">Close Forgot Password</span>
          </button>
        </div>
        {getProgressStep()}
      </div>
    </div>
  );
};

export default Modal;
