import { useState, useCallback } from "react";
import { apiUrl } from "../utils/constants";
import getAccessToken from "../utils/getAccessToken";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "sonner";

export default function useChat({ existingSessionId, isHistoryPage, chatRef }) {
  //setting sessionId
  const [sessionId, setSessionId] = useState(existingSessionId);
  const [isFetchingHistory, setIsFetchingHistory] = useState(false);
  // prev messages are different for homepage and chat history page
  let prevMessages = isHistoryPage
    ? []
    : [{ message: "Hello, how can I help you?", role: "bot" }];

  //defining input, messages and output states
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [messages, setMessages] = useState([...prevMessages]);
  const [output, setOutput] = useState("");

  function handleInputChange(value) {
    setCurrentQuestion(value);
  }

  const navigate = useNavigate();

  const fetchHistory = useCallback(
    async (id) => {
      setMessages([]);
      setIsFetchingHistory(true);
      const prevSessionMessages = await fetch(apiUrl + `api/sessions/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + (await getAccessToken()),
        },
      }).then((res) => res.json());

      if (!prevSessionMessages) return;
      if (prevSessionMessages.error) {
        toast.error("Error Fetching chat history");
        return navigate("/");
      }
      setMessages(prevSessionMessages);
      setIsFetchingHistory(false);
    },
    [navigate]
  );

  const queryClient = useQueryClient();

  //converts words to letters for much better streaming experience.
  async function* streamGenerator(stream) {
    const reader = stream.getReader();
    const decoder = new TextDecoder();
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;

      const chunkvalue = decoder.decode(value);

      if (sessionId) {
        for (let i = 0; i < chunkvalue.length; i++) {
          yield chunkvalue[i];
        }
      } else {
        //try to see if the data is json parseble
        try {
          let newSessionId = JSON.parse(chunkvalue).sessionId;
          if (newSessionId) {
            setSessionId(newSessionId);
            await queryClient.invalidateQueries({ queryKey: ["sessions"] });
            //this starts loading the content
            // return navigate(`/chathistory/${newSessionId}`);
          }
        } catch {
          for (let i = 0; i < chunkvalue.length; i++) {
            yield chunkvalue[i];
          }
        }
      }

      if (done) {
        yield null; // Signal the end of the stream
      }
    }
  }

  let query = currentQuestion;
  const { toggleGenerating } = useOutletContext();

  //get into view
  const getIntoView = (ref) => {
    ref.current?.lastChild?.scrollIntoView({
      behavior: "smooth",
    });
  };

  //handle clicking of send button
  const { mutate: handleSubmit, isPending } = useMutation({
    mutationKey: ["streaming-response"],
    mutationFn: async (e) => {
      e.preventDefault();
      setCurrentQuestion("");

      return await fetch(apiUrl + "api/streamingChat/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + (await getAccessToken()),
        },
        body: JSON.stringify({
          question: query,
          sessionId,
        }),
      }).then((res) => res.body);
    },
    onMutate: () => {
      if (isPending) {
        throw new Error("Please wait for your previous query to end");
      }
      if (!query) throw new Error("Please provide an valid input");
      toggleGenerating(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: currentQuestion,
          role: "human",
        },
      ]);
      getIntoView(chatRef);
    },
    onSuccess: async (stream) => {
      let accRes = "";
      const messageGenerator = streamGenerator(stream);
      let char = await messageGenerator.next();

      while (!char.done) {
        const { value } = char;
        if (value !== null) {
          setOutput((prev) => prev + value);
          accRes = accRes + value; // Accumulate characters into accRes
        }
        char = await messageGenerator.next();
        await new Promise((resolve) => setTimeout(resolve, 10)); // Adjust delay as needed
      }

      //adding the accumulated message to prev messages and settings output to empty string
      setMessages((prev) => [...prev, { message: accRes, role: "bot" }]);
      setOutput("");
      getIntoView(chatRef);
    },
    onError: (error) => {
      setCurrentQuestion(query);
      toast.error(error.message);
    },
    onSettled: () => {
      toggleGenerating(false);
    },
  });

  return {
    isPending,
    currentQuestion,
    messages,
    output,
    handleSubmit,
    handleInputChange,
    fetchHistory,
    isFetchingHistory,
  };
}
