import React from "react";
import BlueLogo from "../../assets/blue_logo.png";
import { PenBox } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const SidebarHeader = ({ generating, isOpen }) => {
  //handling new chat button click
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const handleNewChat = () => {
    if (pathname === "/") window.location.reload();
    else return navigate("/");
  };

  return (
    <div
      className={`flex justify-between items-center fixed top-0 py-8 ${
        isOpen ? "w-[15rem]  bg-[#EBEEF2]" : "w-0 bg-transparent"
      } transition-width duration-300   z-20`}>
      {isOpen && (
        <>
          <button
            onClick={
              generating
                ? () =>
                    toast.error(
                      "Can't Create a new chat when a answer is generating"
                    )
                : handleNewChat
            }>
            <img src={BlueLogo} className={`h-7`} alt="Entourage Logo" />
          </button>
        </>
      )}
      <button
        onClick={
          generating
            ? () =>
                toast.error(
                  "Can't Create a new chat when a answer is generating"
                )
            : handleNewChat
        }
        className={`rounded-full  bg-primary-pink text-white transition-all duration-300  flex justify-center items-center ${
          isOpen
            ? "h-8 w-8 pb-0.5 pl-0.5 ml-0"
            : "h-10 w-10 px-2 pb-0.5 absolute top-5"
        }  `}>
        <PenBox className={`${isOpen ? "h-4" : "h-[18px]"} `} />
      </button>
    </div>
  );
};

export default SidebarHeader;
