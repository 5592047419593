import React, { useState } from "react";
import PasswordField from "../PasswordField";
import Button from "../Button";
import { apiUrl } from "../../utils/constants";
const CreateNewPassword = ({ email, closeModal }) => {
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState("");

  const createNewPass = async (e) => {
    e.preventDefault();
    setCreating(true);
    let newPass = e.target.newPassword.value;
    let cnfNewPass = e.target.confirmNewPassword.value;
    if (newPass !== cnfNewPass) {
      setError("Password and Confirm Password are different");
      setCreating(false);

      return;
    }
    if (newPass.length < 8) {
      setError("Password should contain atleast 8 characters");
      setCreating(false);

      return;
    }
    const response = await fetch(apiUrl + "api/reset-password/", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        new_password: newPass,
        email,
      }),
    }).then((res) => res.json());

    if (!response.success) {
      setError("Invalid Email.");
      setCreating(false);
      return;
    }
    setCreating(false);
    closeModal();
    alert(response.message + " Login using new password");
  };
  return (
    <form
      onSubmit={(e) => createNewPass(e)}
      className="w-full flex flex-col justify-center px-4">
      <h4 className="text-lg text-center">
        Changing password for email:{" "}
        <span className="text-primary-blue">{email}.</span>
      </h4>
      <div>
        <PasswordField name="newPassword" placeholder={"New Password"} />
      </div>
      <div>
        <PasswordField
          name="confirmNewPassword"
          placeholder={"Confirm New Password"}
        />
      </div>
      {error ? <span className="text-red-600 mt-6">{error}</span> : null}
      <div className="mt-10">
        <Button loading={creating} disabled={creating} type="submit">
          Change Password
        </Button>
      </div>
    </form>
  );
};

export default CreateNewPassword;
