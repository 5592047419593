import React, { memo } from "react";
import { Link } from "react-router-dom";

const RedirectSession = ({ session, name }) => {
  const { sessionId, isInView } = session;
  return (
    <Link
      to={`/chathistory/${sessionId}`}
      className={`w-full slide-in px-2 py-3  rounded-md ${
        isInView ? "bg-[#00285E]/10" : "group-hover:bg-[#00285E]/5"
      }  flex justify-between items-center z-10 truncate overflow-ellipsis `}>
      <p className="line-clamp-2 max-w-[85%]"> {name}</p>
    </Link>
  );
};

export default memo(RedirectSession);
