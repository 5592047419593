import React, { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import Button from "./Button";
import InputField from "./InputField";
import PasswordField from "./PasswordField";
import { LogInIcon } from "lucide-react";
import Modal from "./ForgotPassword/Modal";

const LoginForm = () => {
  let { loginUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const signInUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    await loginUser(e);
    setLoading(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <form
        className="flex mt-2 lg:mt-8 flex-col w-full"
        onSubmit={(e) => signInUser(e)}>
        <div>
          <InputField name={"email"} type={"email"} />
        </div>

        <div className="relative w-full ">
          <PasswordField />
          <button
            type="button"
            onClick={() => setIsModalOpen(true)}
            className="text-primary-blue italic text-xs xs:text-base absolute mt-2 right-0">
            Forgot Password?
          </button>
        </div>

        <div className="w-1/2 mt-16 lg:mt-24 self-center">
          <Button
            name="Sign in"
            disabled={loading}
            loading={loading}
            type="submit">
            <LogInIcon className="h-4 w-4 mr-2" /> SignIn
          </Button>
        </div>
      </form>
      {isModalOpen ? (
        <Modal
          closeModal={closeModal}
          inputEmail={document.getElementsByName("email")[0].value}
        />
      ) : null}
    </>
  );
};

export default LoginForm;
