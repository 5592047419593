import React, { useState } from "react";
import { Copy, CopyCheckIcon } from "lucide-react";
import Button from "./Button";
const CopyBtn = ({ text, useIcon = true }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handeCopyText = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  return (
    <>
      {useIcon ? (
        isCopied ? (
          <button alt="Copied Answer" className="w-4 xs:w-6">
            <CopyCheckIcon />
          </button>
        ) : (
          <button
            type="button"
            alt="Copy Answer"
            className="w-4 xs:w-6"
            onClick={() => handeCopyText(text)}>
            <Copy />
          </button>
        )
      ) : isCopied ? (
        <Button>
          <CopyCheckIcon className="mr-2 w-4" />
          Copied
        </Button>
      ) : (
        <Button onClick={() => handeCopyText(text)}>
          <Copy className="mr-2 w-4" />
          Copy to clipboard!
        </Button>
      )}
    </>
  );
};

export default CopyBtn;
