import { X, Trash } from "lucide-react";
import React, { memo, useRef, useEffect } from "react";
import Button from "../Button.js";

const DeleteChat = ({ session, changeSession, deleteChat }) => {
  const { sessionId, deleteSession: sessionName } = session;
  const modalRef = useRef(null);
  //handle clicking outside modal closes it.
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        changeSession(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [changeSession]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    changeSession(null);
    deleteChat(sessionId);
  };
  return (
    <div className="fixed inset-0 bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div
        ref={modalRef}
        className="border border-primary-blue rounded-md p-6 animate-in zoom-in-50   duration-300  bg-white w-full max-w-lg   relative flex flex-col gap-y-2">
        <button
          onClick={() => changeSession(null)}
          className="bg-primary-pink rounded-full px-1.5 absolute -top-2 -right-2 text-white h-8 w-8">
          <X className="h-5 w-5" />
        </button>
        <h4 className="text-lg font-medium ">
          Are you absolutely sure? <br />
        </h4>
        <p className=" text-sm">
          This will delete
          <span className="font-bold "> {sessionName} </span>
        </p>
        <div className="w-full mt-2 flex justify-end gap-x-4">
          <button
            onClick={() => changeSession(null)}
            className="font-medium text-white rounded-lg text-xs xs:text-sm px-5 py-2.5 text-center flex items-center   bg-black/50 hover:bg-black/60">
            <X className="h-4 w-4 mr-2" /> Cancel
          </button>
          <div>
            <Button
              type={"button"}
              name="delete-chat"
              onClick={handleDeleteClick}>
              <Trash className="h-4 w-4 mr-2" /> Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DeleteChat);
