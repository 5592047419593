import React from "react";
import { Bot, User2 } from "lucide-react";
import CopyBtn from "../components/CopyBtn";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
const Messages = ({ messages, output, isloading }) => {
  return (
    <>
      {messages.map((message, index) => {
        const isBotMessage = message.role === "bot";
        return (
          <div
            key={index}
            className={`py-3 xs:py-8   bg-white px-3.5 md:px-1  md:max-w-3xl mx-auto`}>
            <div className=" w-full flex flex-row items-start justify-start gap-x-4 md:gap-x-8">
              <div>
                {isBotMessage ? (
                  <Bot className="w-4 xs:w-7 " />
                ) : (
                  <User2 className="w-4 xs:w-7" />
                )}
              </div>
              <Markdown
                remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                className=" prose">
                {message.message}
              </Markdown>
              {isBotMessage && index !== 0 && (
                <CopyBtn text={message.message} />
              )}
            </div>
          </div>
        );
      })}
      {isloading || output ? (
        <div className={`py-3 xs:py-8  bg-white md:max-w-3xl mx-auto`}>
          <div className=" w-full flex flex-row items-start justify-start gap-x-4 md:gap-x-8">
            <div>
              <Bot className="w-7 h-7" />
            </div>

            <div className="md:text-base  whitespace-pre-wrap prose ">
              {output}
              <span className="md:text-base animate-ping">|</span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="h-16" />
      <div className="h-16" />
    </>
  );
};

export default Messages;
