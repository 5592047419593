import React, { useCallback, useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../utils/constants";
import Loader from "../components/Loader";
const AuthState = (props) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  let [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let loginUser = async (e) => {
    e.preventDefault();
    let response = await fetch(apiUrl + "api/token/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        password: e.target.password.value,
      }),
    });
    let data = await response.json();
    if ((await response).status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      navigate("/");
    } else if ((await response).status === 401) {
      alert("Incorrect Email or Password");
    } else {
      alert("Something went wrong");
    }
  };

  let logOutUser = useCallback(() => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/login");
  }, [navigate]);

  let resetPassword = async (e) => {
    let response = await fetch(apiUrl + "api/change-password/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTokens.access,
      },
      body: JSON.stringify({
        current_password: e.target.oldPassword.value,
        new_password: e.target.newPassword.value,
      }),
    });

    if ((await response).status === 200) {
      alert("Password changed");
      document.location.reload();
    } else {
      alert("Something went wrong");
    }
  };
  //Calling refresh endpoint to refresh tokens
  let updateToken = useCallback(async () => {
    console.log("updating token called");
    let response = await fetch(apiUrl + "api/token/refresh/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: authTokens?.refresh }),
    });
    let data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      logOutUser();
    }
    if (loading) {
      setLoading(false);
    }
  }, [authTokens, loading, logOutUser]);

  let contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logOutUser: logOutUser,
    resetPassword: resetPassword,
  };

  //Calling refresh function every 25 minutes to refresh tokens
  useEffect(() => {
    if (loading) {
      updateToken();
    }
    let TWENTY_FIVE_MINUTES = 1000 * 60 * 25;
    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, TWENTY_FIVE_MINUTES);
    return () => {
      clearInterval(interval);
    };
  }, [authTokens, loading, updateToken]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
};

export default AuthState;
