import React from "react";

const FrameworkInputField = ({ placeholder, id, name, loading = false }) => {
  return (
    <input
      id={id}
      name={name}
      placeholder={placeholder}
      disabled={loading}
      autoFocus={id?.split("-")[0] === "0"}
      required
      className="rounded-md px-4 h-10 w-full  md:w-1/2 placeholder:font-[300]  font-normal text-sm 
    border-2 border-zinc-300 focus:border-zinc-800 disabled:bg-black/5 disabled:border-black/20"
    />
  );
};

export default FrameworkInputField;
