import React from "react";
import { Navigate, Outlet} from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

//Basically render the component or page passed into it if user is logged in
const ProtectedRoutes = (props) =>{
    
    let {user} = useContext(AuthContext)
    if (user){
        return <Outlet />
    }
    else 
    {
        return <Navigate to= 'login' /> 
    }

}

export default ProtectedRoutes