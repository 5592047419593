import React, { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import PasswordField from "../components/PasswordField";
import Button from "../components/Button";
import { RotateCcw } from "lucide-react";
const AccountPage = () => {
  let { user, resetPassword } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const checkPassword = async (e) => {
    e.preventDefault();
    if (e.target.newPassword.value === e.target.oldPassword.value) {
      alert("New Password should not be same as Old Password");
    } else if (e.target.confPassword.value !== e.target.newPassword.value) {
      alert("Confirm Password must be same as New Password");
    } else {
      setLoading(true);
      await resetPassword(e);

      setLoading(false);
    }
  };
  return (
    <div className="my-auto h-[calc(100%-5rem)]  w-full flex justify-center items-center">
      <div className="rounded-2xl shadow-xl bg-white p-8 md:p-16">
        <h1 className="text-xl font-bold  leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          Reset Password
        </h1>

        <form
          className="flex mt-8 flex-col w-64 md:w-[500px]"
          onSubmit={checkPassword}>
          <h5>Signed in as: {user.email}</h5>

          <PasswordField name={"oldPassword"} placeholder={"Old Password"} />

          <PasswordField name={"newPassword"} placeholder={"New Password"} />

          <PasswordField
            name={"confPassword"}
            placeholder={"Confirm Password"}
          />

          <div className="w-1/2 mt-12 self-center">
            <Button
              name="Reset Password"
              type="submit"
              loading={loading}
              disabled={loading}>
              {" "}
              <RotateCcw className="mr-2 h-4 w-4" /> Reset Password{" "}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountPage;
