import React, { forwardRef } from "react";
import { Loader2 } from "lucide-react";

const Sessions = forwardRef(({ userSessions, isFetchingNextPage }, ref) => {
  return (
    <div className="flex flex-col gap-y-4 font-poppins transition-all duration-300 text-sm">
      {userSessions}

      <div ref={ref}></div>
      {isFetchingNextPage ? (
        <div className="w-full flex justify-center items-center">
          <Loader2 className="h-4 w-4 animate-spin" />
        </div>
      ) : null}
    </div>
  );
});

export default Sessions;
