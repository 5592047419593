import React, { useState, useEffect } from "react";
import Select from "react-select";
import getAccessToken from "../utils/getAccessToken";
import { apiUrl } from "../utils/constants";
const SelectBPG = ({
  actionRef,
  autofocusable,
  selected,
  setSelected,
  showSelected = true,
  disabled = false,
}) => {
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(true);

  const fetchOptions = async () => {
    setOptionsLoading(true);
    const token = await getAccessToken();
    try {
      const response = await fetch(apiUrl + "api/getBusinessProfiles/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (!response.body) {
        console.error("Response body not available.");
        setOptionsLoading(false);
        alert("Unable to fetch your profiles");
        return;
      }
      const data = await response.json();
      const optionsData =
        data.map((item) => {
          return {
            label: item.profileName,
            value: item.profileTextValue,
            id: item.id,
          };
        }) ?? null;
      setOptions(optionsData);
      setOptionsLoading(false);
    } catch (error) {
      alert("Uh Oh! Something went wrong");
      console.log(error);
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);
  useEffect(() => {
    actionRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [selected]);
  return (
    <div className="flex flex-col gap-y-3">
      <label className="font-bold text-black/75" htmlFor="select-bsg">
        Select from previously saved Business Profiles.
      </label>
      <Select
        isDisabled={disabled}
        options={options}
        id="select-bsg"
        isLoading={optionsLoading}
        className="max-w-xs"
        required
        name="businessProfileText"
        onChange={(value) => setSelected(value)}
        autoFocus={autofocusable}
      />
      {selected && showSelected ? (
        <div className="bg-white w-full flex justify-start px-3 xs:px-5 py-4 xs:py-8 drop-shadow-md border rounded-lg border-zinc-400">
          <p className="text-xs xs:text-base text-zinc-800 text-left  whitespace-pre-wrap prose">
            {selected.value}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default SelectBPG;
