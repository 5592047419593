import { Loader2 } from "lucide-react";
import React from "react";
const Button = ({
  type,
  name,
  disabled = false,
  loading = false,
  children,
  onClick,
}) => {
  return (
    <button
      type={type}
      name={name}
      disabled={disabled}
      onClick={onClick}
      className="w-full  flex items-center justify-center text-white disabled:opacity-75 disabled:hover:bg-primary-pink bg-primary-pink hover:bg-primary-red focus:outline-1 focus:outline-red-300  font-medium 
    rounded-lg text-xs xs:text-sm px-5 py-2.5 text-center ">
      {loading ? <Loader2 className="h-4 w-4 animate-spin" /> : children}
    </button>
  );
};

export default Button;
