import { Eye, EyeOff } from "lucide-react";
import React, { useState } from "react";

const PasswordField = ({
  name = "password",
  placeholder = name.charAt(0).toUpperCase() + name.slice(1),
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative w-full mt-11">
      <input
        type={show ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        className="bg-white w-full font-normal text-lg xs:text-2xl text-[#959B9D] border-gray-300  rounded-lg block  p-2.5"
        required
      />

      <button
        type="button"
        onClick={() => setShow(!show)}
        className="text-primary-blue text-base absolute top-1/2 lg:top-1/4 right-0">
        {show ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
      </button>

      <div className="w-full h-px  bg-gradient-to-r from-primary-pink via-primary-red to-primary-yellow "></div>
    </div>
  );
};

export default PasswordField;
