import React from "react";
import Framework from "../components/Framework";
import { frameworks } from "../FrameworkData";
import { Link } from "react-router-dom";
import { Cog } from "lucide-react";

const FrameWorkPage = () => {
  return (
    <div className="gap-y-10 px-10 max-w-6xl mx-auto py-10 flex flex-col justify-center items-center">
      <Link
        to="/businessProfile"
        className="self-start group/item  flex items-center justify-center text-white disabled:opacity-75 disabled:hover:bg-primary-pink bg-primary-pink hover:bg-primary-red focus:outline-1 focus:outline-red-300  font-medium 
    rounded-lg text-xs xs:text-sm px-5 py-2.5 text-center ">
        <Cog className="h-4 w-4 mr-2 group-hover/item:rotate-90 transition-all duration-300" />{" "}
        Business Profile Generator
      </Link>

      {frameworks.map((type, index) => {
        return (
          <div className="w-full" key={index}>
            <h1 className="text-3xl  uppercase mb-5 text-primary-blue select-none font-semibold">
              {type.name}
            </h1>
            <ul>
              {type.frameworks.map((framework, index) => {
                return <Framework framework={framework} key={index} />;
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default FrameWorkPage;
