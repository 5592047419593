export const frameworks = [
  {
    name: "Marketing",
    frameworks: [
      {
        name: "Social Media Posts",
        description:
          "Use this framework to create three social media posts for a business, each aimed at addressing the emotional motivators of potential customers without direct selling. The posts will explore customers’ 'Away From' and 'Toward' motivators, along with related emotions, using engaging writing frameworks like AIDA or Problem-Agitate-Solve, use emojis for added engagement, and maintaining a non-commercial tone throughout.",
        requiresBPG: true,
      },
      {
        name: "Blog Posts",
        description:
          "This framework will generate two value-add blog posts focusing on emotional motivators of prospects without overt sales pitches. The posts will identify and address key pain points ('Away From' motivators) and aspirations ('Toward' motivators), using structured writing frameworks like AIDA or SSOFA, subtly countering competitors' claims. They will also position the business as an expert and encourage readers to take personal actions for improvement",
        requiresBPG: true,
      },
      {
        name: "SSOFA (Email)",
        description:
          "This framework will write an email that connects deeply with potential customers by addressing their emotional states and aspirations. This involves understanding the business's context, pinpointing critical pain points and desired outcomes, and crafting a persuasive email using the SSOFA (Situation, Solution, Outcome, Feeling, Agreement) model without sounding salesy",
        requiresBPG: true,
      },
      {
        name: "Landing Page Builder",
        description:
          "This framework will write a complete landing page for you with the nine key sections that every high-converting landing page must have. The framework does this by identifying key pain points and aspirations of the target audience, and then crafting the landing page with elements like headlines, problem-solution sections, social proof, and emotional triggers. The end output is a page that resonates with the audience's needs and desires, encouraging them to take action.",
        requiresBPG: true,
        formFields: [
          {
            label: "Landing Page Description",
            placeholder: "Name of the product, service or lead magnet",
            type: "text",
          },
          {
            label: "Top Outcomes Description",
            placeholder:
              "Top 3 things the product, service or lead magnet helps the target customer achieve.",
          },
        ],
      },
    ],
  },
  {
    name: "Sales",
    frameworks: [
      {
        name: "Buying Motivators",
        description:
          "This framework will help you generate away from and toward motivators, which are the foundation of all effective marketing and sales communications. The framework will first identify the top five 'Away From' motivators (pain points) and 'Toward' motivators (desirable outcomes) that influence prospects' buying decisions. It will then list the five most significant negative emotions linked to the 'Away From' motivators and the five most positive emotions associated with achieving the 'Toward' motivators.",
        requiresBPG: true,
      },
      {
        name: "SSOFA (Sales Conversation)",
        description:
          "Use this framework to write a sales script that focuses on understanding and addressing the emotional motivators of prospects. It does this by applying the SSOFA model (Situation, Solution, Outcomes, Feelings, Agreement) to communicate the product or service effectively, using a conversational tone without sounding salesy. The emphasis is on creating a brief, engaging narrative that resonates with the prospect's emotional states and needs.",
        requiresBPG: true,
      },
    ],
  },
  {
    name: "People",
    frameworks: [
      {
        name: "Job Ad",
        description:
          "This framework will write a detailed and engaging job ad that follows The Entourage’s tried and tested job ad template. The ad will include sections about the candidate, the role and responsibilities, goals for the first three months, required experience, skills, and qualifications, and reasons why a candidate would love this role. This structured format aims to provide a comprehensive overview of the job, attract suitable candidates, and reflect the company's culture and expectations for the role.",
        requiresBPG: true,
        formFields: [
          {
            label: "Job Title",
            placeholder: " The title of the job we are advertising for.",
            type: "text",
          },
          {
            label: "Job Roles and Responsibilities",
            placeholder: "What the job does and is responsible for?",
          },
          {
            label: "Three month goal",
            placeholder:
              "What the successful candidate will achieve in the first three months?",
          },
          {
            label: "Experience, Skills & Responsibilities",
            placeholder: "Experience, Skills & Qualifications necessary",
          },
        ],
      },
      {
        name: "Performance Improvement Plan",
        description:
          "Use this framework to develop a Performance Improvement Plan (PIP) that follows Australian Fair Work best practices.",
        requiresBPG: true,
        underconstruction: true,
      },
    ],
  },
];

export const bsgData = {
  name: "Business Profile Generator",
  description: `Please provide the requested information to create a profile that consists of a business overview, customer avatar, product benefits, and tone of voice. This profile will be saved for you to use whenever you need it. 
        
        To create a business profile, fill out the details below and then press GO.`,
  formFields: [
    {
      label: "Business Name",
      placeholder: "Enter the business's name here",
      type: "text",
    },
    {
      label: "Business Overview",
      placeholder:
        "Provide as much detail as possible about the business and what it does.",
    },
    {
      label: "Target Customer",
      placeholder:
        "Who is the target customer and what problem are they looking to solve?",
    },
    {
      label: "Product Benefits",
      placeholder:
        "How does the business's product or service help the target customer solve their problem?",
    },
    {
      label: "Tone of Voice",
      placeholder:
        "What is the tone of voice of the business? Is it serious, cheeky, professional, or caring? Enter a few keywords here.",
    },
  ],
};
