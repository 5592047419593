import React from 'react'

const InputField = ({type,name,placeholder=name.charAt(0).toUpperCase() + name.slice(1),required=true}) => {
  return (
    <>
    <input
		type={type}
		name={name}
		className="bg-white w-full font-normal  text-[#959B9D] text-lg xs:text-2xl  block  p-2.5  "
		placeholder={placeholder}
		required={required}
	/>
        <div className="w-full h-px  bg-gradient-to-r from-primary-pink via-primary-red to-primary-yellow "></div>

    </>
  )
}

export default InputField