import React, { forwardRef, memo } from "react";

const RenameField = forwardRef(
  ({ session, name, handleRename, handleChangeRenameField }, inputRef) => {
    const { sessionName, sessionId, isInView } = session;
    return (
      <div
        className={`w-full slide-in px-2 py-3  rounded-md ${
          isInView ? "bg-[#00285E]/10" : "bg-[#00285E]/5"
        }  flex justify-between items-center z-10`}>
        <input
          ref={inputRef}
          id={sessionId + "-input" + sessionName}
          value={name}
          autoFocus
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleRename(sessionId, name, sessionName);
            }
          }}
          className={`w-full border rounded-md border-primary-blue px-2 py-1 ${
            isInView ? "bg-[#00285E]/10" : "bg-[#00285E]/5"
          }`}
          onChange={handleChangeRenameField}
        />
      </div>
    );
  }
);

export default memo(RenameField);
