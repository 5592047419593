import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Loader2 } from "lucide-react";
import Session from "./Session";

import useSession from "../../hooks/useSession";
import SidebarHeader from "./SidebarHeader";
import Sessions from "./Sessions";
import DeleteChat from "./DeleteChat";
const Sidebar = ({ generating, isOpen }) => {
  const {
    handleRename,
    changeSession,
    sessionState,
    status,
    sessionData,
    isFetchingNextPage,
    ref,
    deleteChat,
  } = useSession();

  //route info
  const pathname = useLocation().pathname;

  //refs to pass to session
  const popoverRef = useRef(null);
  const inputRef = useRef(null);

  //mapping through pages to render session
  const userSessions = sessionData?.map((session) => {
    // passing info like isMenuOpen , isRename, delete
    const sessionInfo =
      sessionState && sessionState.sessionId === session.sessionId
        ? sessionState
        : null;

    const sessionProps = {
      ...session,
      sessionState: sessionInfo,
      isInView: pathname.includes(`/${session.sessionId}`),
    };
    return (
      <Session
        key={session.sessionId}
        session={sessionProps}
        generating={generating}
        changeSession={changeSession}
        handleRename={handleRename}
        ref={{ popoverRef, inputRef }}
      />
    );
  });

  //handle clicking outside input or popover closes them
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !event.target.id.endsWith("-img")
      ) {
        changeSession(null);
      }
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        const newName = inputRef.current.value;
        const [sessionToEdit, sessionOldName] =
          inputRef.current.id.split("-input");

        handleRename(sessionToEdit, newName, sessionOldName);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [changeSession, handleRename]);

  //fetching next page if last session is in view

  return (
    <>
      <div className="py-4 px-4 space-y-12 transition-all duration-300">
        <SidebarHeader generating={generating} isOpen={isOpen} />
        <div className="space-y-4">
          <h4 className=" font-bold text-sm">Chat Logs</h4>

          {status === "pending" ? (
            <div className="w-full h-[calc(100vh-7rem)] flex justify-center items-center">
              <Loader2 className="h-6 w-6 animate-spin" />
            </div>
          ) : status === "success" ? (
            userSessions.length > 0 ? (
              <Sessions
                userSessions={userSessions}
                ref={ref}
                isFetchingNextPage={isFetchingNextPage}
              />
            ) : (
              <div>No chat history found</div>
            )
          ) : (
            <div>No chat history found</div>
          )}
        </div>
      </div>
      {sessionState && sessionState.deleteSession ? (
        <DeleteChat
          session={sessionState}
          changeSession={changeSession}
          deleteChat={deleteChat}
        />
      ) : null}
    </>
  );
};

export default Sidebar;
