import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";

import { ChevronLeft } from "lucide-react";

const SidebarLayout = () => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1024);
  const [generating, setGenerating] = useState(false);
  const toggleGenerating = (value) => {
    setGenerating(value);
  };
  useEffect(() => {
    const matchQuery = () => {
      if (window.innerWidth <= 1024) setIsOpen(false);
    };
    var mediaQuery = window.matchMedia("(max-width:1024px)");
    mediaQuery.addEventListener("change", matchQuery);
    return () => mediaQuery.removeEventListener("change", matchQuery);
  }, []);
  return (
    <div>
      <div className="  flex relative  ">
        <div
          className={`${
            isOpen ? "w-[18rem]" : "w-0 overflow-hidden"
          }  transition-width duration-300 fixed left-0 top-0 z-30 bg-[#EBEEF2] h-full`}>
          <div className="max-h-screen relative z-40   h-full py-8  mr-1 hover:mr-0 overflow-y-hidden hover:overflow-y-scroll  scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
            <Sidebar generating={generating} isOpen={isOpen} />
          </div>
        </div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`fixed ${
            isOpen ? "left-[18rem] " : "left-0"
          }  top-1/2 -translate-y-1/2 bg-[#EBEEF2] transition-all duration-300 text-black h-24 flex justify-center items-center rounded-r-xl`}>
          <ChevronLeft
            className={`h-6 w-6 ${
              isOpen ? "rotate-0" : "rotate-180"
            } transition-all duration-300 select-none`}
          />
        </button>
        <div
          className={` ${
            isOpen ? "lg:ml-[18rem]" : "ml-0"
          } text-base transition-all duration-300  font-normal text-black font-poppins w-full`}>
          <Outlet context={{ isOpen, toggleGenerating }} />
        </div>
      </div>
    </div>
  );
};

export default SidebarLayout;
