import React, { useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Logo from "../assets/noTagLogo.png";
import { Link, useLocation } from "react-router-dom";

import Button from "../components/Button";
import { LogInIcon, Menu, LogOut as SignoutIcon, X } from "lucide-react";
const Header = () => {
  let { user, logOutUser } = useContext(AuthContext);
  const location = useLocation().pathname;
  const links = [
    {
      to: "/",
      linkName: "Chat",
    },
    // {
    //   to: "/chathistory",
    //   linkName: "Chat History",
    // },
    {
      to: "/frameworks",
      linkName: "Frameworks",
    },
    {
      to: "/savedFrameworks",
      linkName: "Saved Frameworks",
    },
    {
      to: "/account",
      linkName: "Account",
    },
  ];
  const LogIn = () => {
    return (
      <Link
        className="w-full  flex items-center justify-center text-white disabled:opacity-75 disabled:hover:bg-primary-pink bg-primary-pink hover:bg-primary-red focus:outline-1 focus:outline-red-300  font-medium 
    rounded-lg text-xs xs:text-sm px-5 py-2.5 text-center "
        to="login">
        <LogInIcon className="w-4 mr-2" /> Sign In
      </Link>
    );
  };

  const LogOut = () => {
    return (
      <Button name="Sign out" onClick={logOutUser} type="button">
        <SignoutIcon className="w-4  mr-2" /> Sign Out
      </Button>
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const hasSideBar = location === "/" || location.includes("/chathistory");

  return (
    <nav className="bg-primary-blue h-20 ">
      {isOpen ? (
        <div
          className="lg:hidden  absolute gap-y-8  h-screen bg-white drop-shadow-lg right-0 sm:max-w-lg w-full  z-[100] justify-start pt-20 items-center flex flex-col"
          id="mobile-menu-2">
          <button
            data-collapse-toggle="mobile-menu-2"
            type="button"
            className="inline-flex absolute right-5 top-5 items-center p-2 ml-2 text-sm rounded-lg text-primary-blue hover:text-white lg:hidden hover:bg-primary-pink focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="mobile-menu-2"
            aria-expanded="false"
            onClick={() => setIsOpen(!isOpen)}>
            <X className="h-6 w-6 " />
          </button>
          <ul className="flex flex-col gap-y-4 font-bold text-2xl drop-shadow text-white ">
            {links.map((link, index) => {
              let isLocation = location.split("/")[1] === link.to.split("/")[1];
              let isHighlighted = hasSideBar
                ? link.to.split("/")[1] === ""
                : isLocation;
              return (
                <li key={index}>
                  <Link
                    to={link.to}
                    onClick={() => setIsOpen(!isOpen)}
                    className={`flex gap-2 items-center ${
                      isHighlighted ? "text-primary-pink" : "text-primary-blue"
                    } hover:underline underline-offset-4 decoration-2 uppercase  `}
                    aria-current="page">
                    {link.linkName}
                  </Link>
                </li>
              );
            })}
            {user ? <LogOut /> : <LogIn />}
          </ul>
        </div>
      ) : null}
      <div
        className={` ${
          hasSideBar && "lg:ml-[18rem]"
        } flex flex-row justify-between h-full items-center mx-auto xl:max-w-screen-xl px-6`}>
        <div className="flex items-center h-full order-2">
          {user ? <LogOut /> : <LogIn />}

          {isOpen ? null : (
            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 ml-2 text-sm rounded-lg lg:hidden hover:bg-primary-pink focus:outline-none focus:ring-2 focus:ring-gray-200 "
              aria-controls="mobile-menu-2"
              aria-expanded="false"
              onClick={() => setIsOpen(!isOpen)}>
              <span className="sr-only">
                {isOpen ? "Close" : "Open"} main menu
              </span>
              <Menu className="w-6 h-6 text-white" />
            </button>
          )}
        </div>
        {!hasSideBar ? (
          <Link to="/">
            <img src={Logo} alt="logo" className="h-10" />
          </Link>
        ) : (
          <div />
        )}
        <div
          className="hidden justify-evenly items-center w-full lg:flex lg:w-auto order-1"
          id="mobile-menu-2">
          <ul className="flex  gap-x-10 font-bold text-sm xl:text-base text-white flex-row lg:space-x-6 xl:space-x-8 mt-0">
            {links.map((link, index) => {
              let isLocation = location.split("/")[1] === link.to.split("/")[1];
              let isHighlighted = hasSideBar
                ? link.to.split("/")[1] === ""
                : isLocation;

              return (
                <li key={index}>
                  <Link
                    to={link.to}
                    className={`  ${
                      isHighlighted ? "text-primary-pink" : "text-white"
                    } hover:underline underline-offset-4 decoration-2 uppercase  `}
                    aria-current="page">
                    {link.linkName}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
