import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { frameworks } from "../FrameworkData";
import Loader from "../components/Loader";
import Button from "../components/Button";
import FrameworkTextField from "../components/FrameworkTextField";
import FrameworkInputField from "../components/FrameworkInputField";
import { convertToCamelCase } from "../utils";
import getAccessToken from "../utils/getAccessToken";
import { apiUrl } from "../utils/constants";
import SelectBPG from "../components/SelectBPG";
import TextareaAutosize from "react-textarea-autosize";
import CopyBtn from "../components/CopyBtn";
import { Edit, Loader2, Save, Send, X, XCircle } from "lucide-react";

const SingleFrameworkPage = () => {
  const params = useParams();
  const [framework, setFramework] = useState(null);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [isAiThinking, setIsAiThinking] = useState(false);
  const [output, setOutput] = useState("");
  const [saving, setSaving] = useState(false);
  const outputRef = useRef(null);
  const goRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [selected, setSelected] = useState(null);
  const dialogRef = useRef(null);
  const findFramework = useCallback(() => {
    frameworks.forEach((type) => {
      let found = type.frameworks.find(
        (framework) => framework.name === params.framework
      );

      if (typeof found !== "undefined") {
        setFramework(found);
        setLoading(false);

        return;
      }
    });
    setLoading(false);
  }, [params.framework]);

  useEffect(() => {
    findFramework();
  }, [params, findFramework]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOutput("");
    setIsAiThinking(true);
    setGenerating(true);

    let promptParams = {};

    for (let i = 0; i < e.target.length - 1; i++) {
      const element = e.target[i];
      if (!element.name) continue;
      if (element.name === "businessProfile")
        promptParams[element.name] = element.value;
      else promptParams[element.name] = element.value;
    }
    const token = await getAccessToken();
    try {
      const response = await fetch(apiUrl + "api/streamingframeworks/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          promptParams,
          frameworkName: framework.name,
        }),
      });
      if (!response.body) {
        console.error("Response body not available.");
        alert("something went wrong");
        setGenerating(false);
        setIsAiThinking(false);
        return;
      }

      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();
      setIsAiThinking(false);
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setGenerating(false);
          setIsAiThinking(false);
          break; // End of stream
        }
        const renderedData = textDecoder.decode(value, { stream: true });
        setOutput((prev) => `${prev}${renderedData}`);
      }
    } catch (error) {
      setGenerating(false);
      setIsAiThinking(false);
      alert("something went wrong");
    }
  };
  useEffect(() => {
    outputRef.current?.scrollIntoView({ behavior: "instant", block: "end" });
  }, [output, generating]);

  const saveAsFavourite = async (e) => {
    e.preventDefault();
    setSaving(true);
    const token = await getAccessToken();

    const response = await fetch(apiUrl + "api/saveFrameworkAsFavourite/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        category: framework.name,
        frameworkName: e.target.userFrameworkName.value,
        actualFrameworkText: output,
        businessProfile: selected.id,
      }),
    });
    if (!response.body) {
      console.error("Response body not available.");
      setSaving(false);
      alert("Unable to fetch your profiles");
      return;
    }
    const { message } = await response.json();
    if (message === "saved") {
      alert(
        "Your Framework is saved, you can now view it in 'Saved Framework' page!"
      );
      setSaving(false);
      dialogRef.current.close();
    } else {
      alert(message);
      setSaving(false);
    }
  };
  return (
    <div className=" bg-[#EBEBEB] gap-y-10 py-10 flex flex-col justify-center items-center off-smooth-scroll">
      {loading ? (
        <div className="h-[calc(100vh-10rem)]  flex items-center ">
          <Loader />
        </div>
      ) : framework ? (
        <div className="w-full px-10 max-w-5xl   space-y-10 min-h-[calc(100vh-10rem)] ">
          <div className="w-full space-y-3">
            <h1 className=" text-3xl font-bold text-primary-blue">
              {framework?.name}
            </h1>
            <p className="whitespace-pre-line">{framework?.description}</p>
          </div>

          <form onSubmit={handleSubmit} className="flex flex-col gap-y-5">
            {framework.requiresBPG ? (
              <SelectBPG
                actionRef={goRef}
                autofocusable={framework.formFields ? false : true}
                selected={selected}
                setSelected={setSelected}
              />
            ) : null}

            {framework.formFields?.map((field, index) => {
              let id = index + "-" + field.label;
              let name = convertToCamelCase(field.label);
              return (
                <div key={index} className="flex flex-col space-y-2">
                  <label className="font-bold text-black/75" htmlFor={id}>
                    {field.label}
                  </label>
                  {typeof field.type !== "undefined" ? (
                    <FrameworkInputField
                      key={id}
                      id={id}
                      placeholder={field.placeholder}
                      name={name}
                      loading={generating}
                    />
                  ) : (
                    <FrameworkTextField
                      key={id}
                      id={id}
                      placeholder={field.placeholder}
                      name={name}
                      loading={generating}
                    />
                  )}
                </div>
              );
            })}

            <div className="self-end" ref={goRef}>
              <Button
                type="submit"
                name="Submit Form"
                disabled={generating || saving}
                loading={generating || saving}>
                <Send className="h-4 w-4" />
              </Button>
            </div>
          </form>
          {isAiThinking || output ? (
            <div className="sm:max-w-full mt-12  mx-auto gap-y-8 flex flex-col">
              <h6 className="font-bold text-lg xs:text-2xl text-black/75">
                Generated Output:
              </h6>

              <div className="bg-white  w-full  flex justify-start px-3 xs:px-5 py-4 xs:py-8 drop-shadow-md border rounded-lg  z-30 border-zinc-400">
                {isAiThinking ? (
                  <div className="self-center flex flex-row items-start gap-x-2 xs:gap-x-8 mx-auto">
                    <Loader2 className="w-3 xs:w-6 aspect-square animate-spin" />
                    <div className="flex gap-x-2">
                      <p className="text-xs xs:text-base">
                        Generating your answer{" "}
                      </p>
                      <span className="animate-bounce">.</span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "300ms" }}>
                        .
                      </span>
                      <span
                        className="animate-bounce"
                        style={{ animationDelay: "600ms" }}>
                        .
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="text-xs xs:text-base w-full text-zinc-800 text-left text-clip overflow-hidden whitespace-pre-wrap prose ">
                    {editMode ? (
                      <TextareaAutosize
                        autoFocus
                        onChange={(e) => setOutput(e.target.value)}
                        className="w-full p-2 rounded-md whitespace-pre-wrap prose scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touc"
                        value={output}
                      />
                    ) : (
                      <>
                        {output}
                        {generating && <span className="animate-ping">|</span>}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className=" items-center w-full flex justify-evenly flex-col md:flex-row gap-y-4  self-center">
                {!generating ? (
                  <>
                    <div className="md:basis-1/4">
                      <CopyBtn useIcon={false} text={output} />
                    </div>
                    <div className="md:basis-1/4">
                      <Button onClick={() => setEditMode(!editMode)}>
                        {editMode ? (
                          <>
                            <X className="w-4 h-4 mr-2" /> Close Editing Mode
                          </>
                        ) : (
                          <>
                            <Edit className="h-4 w-4 mr-2" /> Open Editing Mode
                          </>
                        )}
                      </Button>
                    </div>
                    <div className="md:basis-1/4">
                      <Button
                        disabled={saving || editMode}
                        loading={saving}
                        onClick={() => dialogRef.current.showModal()}
                        type="button">
                        {editMode ? (
                          "Close Edit Mode before Saving"
                        ) : (
                          <>
                            <Save className="h-4 w-4 mr-2" /> Save as Favourite
                          </>
                        )}
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          <div ref={outputRef} className=" h-10" />
        </div>
      ) : (
        <div className="h-[calc(100vh-10rem)] flex-col flex items-center justify-center gap-y-5">
          <h1 className=" text-3xl font-bold text-primary-blue">
            No Framework Found
          </h1>
          <Link to="/frameworks">
            <Button name={"Frameworks Page"} type={"button"}>
              Frameworks
            </Button>
          </Link>
        </div>
      )}
      <dialog
        ref={dialogRef}
        className="space-y-4 rounded-md p-4 border border-black">
        <div className="space-y-1">
          <div className="flex justify-between">
            <h3 className=" text-2xl font-bold text-primary-blue">
              Save Framework
            </h3>

            <button
              type="button"
              onClick={() => dialogRef.current.close()}
              className=" text-red-500 font-bold">
              <XCircle />
            </button>
          </div>
          <p className="text-lg text-primary-pink">
            <span className="text-gray-700/80 mr-2 text-base">
              Business Profile Used:
            </span>
            {selected?.label}
          </p>
        </div>
        <form
          onSubmit={saveAsFavourite}
          className="flex gap-y-2 flex-col w-full min-w-[20rem]">
          <input
            required
            placeholder="Give a name to save framework"
            name="userFrameworkName"
            className="rounded-md px-4 h-10 w-full  placeholder:font-[300]  font-normal text-xs xs:text-sm
    border-2 border-zinc-300 focus:border-zinc-800 disabled:bg-black/5 disabled:border-black/20"
          />
          <Button disabled={saving} loading={saving} type="submit">
            <Save className="h-4 w-4 mr-2" /> Save as Favourite
          </Button>
        </form>
      </dialog>
    </div>
  );
};

export default SingleFrameworkPage;
