export const convertToCamelCase = (label) => {
  let labelArray = label.split(" ");
  labelArray[0] = labelArray[0].toLowerCase();
  for (let i = 1; i < labelArray.length; i++) {
    let singleWordArray = labelArray[i].split("");
    let firstLetter = singleWordArray[0].toUpperCase();
    let restOfTheWord = singleWordArray
      .slice(1, singleWordArray.length)
      .join("")
      .toLowerCase();

    labelArray[i] = firstLetter + restOfTheWord;
  }
  let camelCaseWord = labelArray.join("").replace(",", "").replace("&", "And");
  return camelCaseWord;
};
