import { useState, useRef, useEffect } from "react";
import SelectBPG from "../components/SelectBPG";
import { apiUrl } from "../utils/constants";
import getAccessToken from "../utils/getAccessToken";
import Skeleton from "react-loading-skeleton";
import CopyBtn from "../components/CopyBtn";
import { XCircle } from "lucide-react";
const SavedFrameworks = () => {
  const [selected, setSelected] = useState(null);
  const [openedFramework, setOpenedFramework] = useState(null);
  const [frameworks, setFrameworks] = useState([]);
  const [loading, setLoading] = useState(false);
  const goRef = useRef(null);
  const dialogRef = useRef(null);
  const handleView = (framework) => {
    setOpenedFramework(framework);
    dialogRef.current.showModal();
  };

  useEffect(() => {
    if (!selected) return;
    const fetchFrameworks = async () => {
      setLoading(true);
      setFrameworks([]);
      try {
        const token = await getAccessToken();

        const response = await fetch(apiUrl + "api/getSavedFrameworks/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ businessProfileId: selected.id }),
        });
        if (!response.body) {
          console.error("Response body not available.");
          setLoading(false);
          alert("Unable to fetch your framworks");
          return;
        }
        const data = await response.json();
        setFrameworks(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchFrameworks();
  }, [selected]);

  return (
    <div className="bg-[#EBEBEB] min-h-[calc(100vh-5rem)]">
      <div className="mx-auto w-full  max-w-screen-xl px-2.5 md:px-20 flex flex-col py-8 gap-y-8">
        <SelectBPG
          selected={selected}
          setSelected={setSelected}
          actionRef={goRef}
          autofocusable={true}
          showSelected={false}
          disabled={loading}
        />
        <div ref={goRef}>
          {frameworks.length > 0 ? (
            <table
              className="w-full"
              style={{
                tableLayout: "fixed",
                borderCollapse: "separate",
                borderSpacing: "0 15px",
              }}>
              <thead className="text-left">
                <tr>
                  <th scope="col" className="text-center px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Category
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="space-y-4">
                {frameworks.map((framework, index) => {
                  return (
                    <tr
                      key={framework.id}
                      className={`shadow-md hover:shadow-xl  ${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}>
                      <td
                        scope="col"
                        className="text-center px-6 py-4  rounded-l-md">
                        {index + 1}
                      </td>
                      <td scope="col" className="px-6 py-4">
                        {framework.frameworkName}
                      </td>
                      <td scope="col" className="px-6 py-4">
                        {framework.category}
                      </td>
                      <td scope="col" className="px-6 py-4 rounded-r-md">
                        <button
                          type="button"
                          onClick={() => handleView(framework)}
                          className="bg-none border-0 hover:underline underline-offset-2">
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : loading ? (
            <div className="py-10">
              <Skeleton
                height={25}
                count={3}
                baseColor="white"
                highlightColor="#00275E"
              />
            </div>
          ) : (
            <>
              <p>Select a Business Profile.</p>
              <p className="text-sm">
                If you have selected a Business Profile but don't see any
                frameworks, save a framework&apos;s output which uses this
                Business Profile
              </p>
            </>
          )}
          <dialog
            ref={dialogRef}
            className="px-8 py-2 rounded-md max-h-[calc(100vh-7rem)]  scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch shadow-md border border-black">
            {openedFramework ? (
              <div className="flex flex-col py-4">
                <button
                  type="button"
                  onClick={() => dialogRef.current.close()}
                  className="self-end mb-5 text-red-400">
                  <XCircle className="w-8" />
                </button>
                <div className="flex flex-col gap-y-4">
                  <div className="flex justify-between">
                    <h3 className=" text-2xl font-bold text-primary-blue">
                      {openedFramework.frameworkName}
                    </h3>
                    <div className="basis-3/12">
                      <CopyBtn
                        text={openedFramework.actualFrameworkText}
                        useIcon={false}
                      />
                    </div>
                  </div>
                  <h3 className="text-lg text-primary-pink">
                    <span className="text-gray-700/80 mr-2 text-base">
                      Category:
                    </span>
                    {openedFramework.category}
                  </h3>
                  <p className="whitespace-pre-wrap prose max-w-screen-lg">
                    {openedFramework.actualFrameworkText}
                  </p>
                </div>
              </div>
            ) : null}
          </dialog>
        </div>
      </div>
    </div>
  );
};

export default SavedFrameworks;
